import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"

//component
import Menu from "../components/Menu"
//css
import "../css/nav.scss"
//data
const getData = graphql`
  {
    c: allContentfulLocalite(sort: { fields: ville, order: ASC }) {
      nodes {
        ville
        pays
        id
      }
    }
  }
`
const Lieux = () => {
  const data = useStaticQuery(getData)
  console.log(data)
  let rawData = data.c.nodes.reduce((r, e) => {
    // get first letter of name of current element
    let group = e.ville[0]
    // if there is no property in accumulator with this letter create it
    if (!r[group]) r[group] = { group, children: [e] }
    // if there is push current element to children array for that letter
    else r[group].children.push(e)
    // return accumulator
    return r
  }, {})

  let result = Object.values(rawData)
  return (
    <div>
      <div className="navTree">
        <fieldset>
          <h2>Navigation</h2>
          <Menu />
        </fieldset>
      </div>
      <h1>Lieux</h1>
      <div className="lieux">
        {result.map((alpha, index) => {
          return (
            <div key={index}>
              <h3>{alpha.group}</h3>
              {alpha.children.map((item, index) => {
                return (
                  <div key={index}>
                    <Link to={`/lieux/${item.id}`}>
                      {item.ville} ({item.pays})
                    </Link>
                  </div>
                )
              })}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Lieux
